import React from 'react';
import { MainLink } from '../../route';
import {
  FacebookShareButton,
  VKShareButton
} from "react-share";

const ShareForm = () => {
  const shareUrl = MainLink;
  const title = 'Радиационная обстановка на предприятиях Росатома';
  return(
    <>
      <h2 className="modal-head">поделиться</h2>
      <ul className="social">
        <li>
          <VKShareButton
            url={shareUrl}
            quote={title}
          >
            <a className="btn-outline btn-round"><img src={process.env.PUBLIC_URL + "/img/vk_icon.svg"} alt={""} /></a>
          </VKShareButton>
        </li>
        <li>
          <FacebookShareButton
            url={shareUrl}
            quote={title}
          >
            <a className="btn-outline btn-round"><img src={process.env.PUBLIC_URL + "/img/fb_icon.svg"} alt={""} /></a>
          </FacebookShareButton>
        </li>
      </ul>
    </>
  )
}

export default ShareForm;