// @flow

import React, {useContext} from 'react';
import Context from './../../context';
import { GetColor } from '../../common/Services';
import TooltipHelp from '../../common/TooltipHelp';
import { HelpContent } from '../../common/Parser';
import ButtonHelp from './../../common/ButtonHelp';

const SensorReadingModal = () => {
  const {stateSensor, setStateSensor} = useContext(Context);
  return(
    <div className="modal-sensor modal-small modal-dark" >
      <div className="modal-inner">
        <div className="modal-pad">
          <div className="modal-top">
            <div className="modal-top-inner">
              <h2 className="modal-head">{stateSensor.sensor.objectMap.name}</h2>
              <button type="button" className="modal-close" onClick={()=>setStateSensor({...stateSensor, isOpenReading: false})}>
                <span className="diagonal-line"></span>
                <span className="diagonal-line-reverse"></span>
              </button>
            </div>
          </div>
          <div className="modal-main">
            <div className={"sensor-table sensor-"+GetColor(stateSensor.sensor.objectMap.icon)}>
              <h4 className="sensor-table-title">{getTitle(stateSensor.sensor.objectMap.icon)}</h4>
              { stateSensor.sensor.value != null &&
                <div className="row">
                  <div className="col align-items-start">
                    <span className="sensor-table-sub">Мощность<br/> дозы<sup>мкЗв/ч</sup></span>
                  </div>
                  <div className="col sensor-table-value align-items-end">{stateSensor.sensor.value}</div>
                </div>
              }
            </div>
            <button type="button" className="btn" onClick={()=>setStateSensor({...stateSensor, isOpenReading: false, isOpenHistory: true})}>
              подробнее
              <span className="btn-stroke"></span>
            </button>
            <TooltipHelp message={HelpContent.find(cont => cont.name === "values").description} customButton={<ButtonHelp />} />
          </div>
        </div>	
      </div>	
    </div>
  )
}
export default SensorReadingModal;


const getTitle = (icon: string) => {
  switch(icon){
    case "green":
      return "Естественный природный фон";
    case "darkGreen":
      return "Превышение естественного природного фона не более чем в 2 раза";
    case "violet":
      return "Превышение естественного природного фона более чем в 2 раза";
    case "purple":
      return "Превышение естественного природного фона более чем в 2 раза";
    case "repair":
      return "Датчик на ремонте";
    case "gray":
      return "Нет данных";
    default:
      Error();
  }
}