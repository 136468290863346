// @flow

import React, {useState, useEffect} from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import SensorReadingModal from './SensorReadingModal';
import SensorHistoryModal from './history/SensorHistoryModal';
import Context from '../../context';
import { isMobile } from 'react-device-detect';
import type { SensorModel } from '../../models/SensorModel';
import { ChangeSizeCanvas } from './history/SensorGraph';

export var OpenSensorModal: (sensor: SensorModel) => void;
export var OpenSensorGraphModel: (sensor: SensorModel) => void;
export var CloseSensorModel: (sensor: SensorModel) => void;

const SensorModal = () => {
  const[stateSensor, setStateSensor] = useState({
    sensor: null,
    isOpenReading: false,
    isOpenHistory: false
  });

  useEffect(()=> {
    if(isMobile) return;
    const windowsHistory = document.getElementById('windowsHistory');
    window.removeEventListener('resize', ()=>UpdateSizeWindow(windowsHistory));
    window.removeEventListener('resize', ChangeSizeCanvas);
    if(!isOpenHistory) return;
    window.addEventListener('resize', ()=>UpdateSizeWindow(windowsHistory));
    UpdateSizeWindow(windowsHistory);
  });

  OpenSensorModal = (sensor: SensorModel) =>
    setStateSensor({...stateSensor, isOpenReading: true, sensor});

  OpenSensorGraphModel = (sensor: SensorModel) => 
    setStateSensor({...stateSensor, isOpenHistory: true, sensor});

  CloseSensorModel = () => 
    setStateSensor({...stateSensor, isOpenHistory: false, isOpenReading: false});
  
  const{isOpenReading, isOpenHistory} = stateSensor;

  am4core.disposeAllCharts();

  return(
    <Context.Provider value={{stateSensor, setStateSensor}}>
      {isOpenReading && <SensorReadingModal />}
      {isOpenHistory && <SensorHistoryModal />}
    </Context.Provider>
  )
}

export default SensorModal;

const UpdateSizeWindow = (windowsHistory: HTMLElement) => {
  const x_pos = (document.documentElement.clientWidth- windowsHistory.offsetWidth) / 2;
  windowsHistory.style.left = x_pos+'px';
}