// @flow

import React, {useEffect, useReducer} from 'react';
import Context from '../../context';
import SubscribeModal from '../../modals/subscribe/SubscribeModal';
import { OpenSensorList } from './../../modals/sensor/list/SensorListModal';
import { OpenHeaderMenu } from './../header/HeaderView';

export var OpenMap: () => view;

const initialState = {
  isOpenSubscribe: false,
  isOpenList: false,
  isOpenMap: true
};

function reducer(state, action) {
  switch (action.type) {
    case 'OpenMap':
      return {...state, isOpenList: false, isOpenMap: true};
    case 'OpenList':
      return {...state, isOpenList: true, isOpenMap: false};
    case 'OpenSubscribe':
      return {...state, isOpenSubscribe: true};
    case 'CloseSubscribe':
      return {...state, isOpenSubscribe: false};
    default:
      throw new Error();
  }
}

const FooterView = () => {
  const [footerState, dispatchFooter] = useReducer(reducer, initialState);

  const {isOpenSubscribe, isOpenList, isOpenMap} = footerState;
  OpenMap = () => dispatchFooter({type: 'OpenMap'});
  useEffect (() => {
    OpenSensorList(isOpenList);
  });

  const isActive = (active: boolean) => {
    var state = '';
    if(active) state = "active";
    return state
  }
  
  return(
    <Context.Provider value={{dispatchFooter}}>
      <footer className="footer">
        <div className="container">
          <div className="footer-inner">
            <div className="footer-container">
              <ul className="footer-menu">
                <li className={isActive(isOpenList)}>
                  <a onClick={()=>dispatchFooter({type: 'OpenList'})}>
                    <div className="menu-link" >
                      <span className="menu-icon"><img src={process.env.PUBLIC_URL + "/img/point_outline.svg"} alt={""}></img></span>
                      <span className="menu-icon hidden"><img src={process.env.PUBLIC_URL + "/img/point.svg"} alt={""}/></span>
                      список
                    </div>
                  </a>
                </li>
                <li className={isActive(isOpenMap)}>
                  <a onClick={OpenMap}>
                    <div className="menu-link" >
                      <span className="menu-icon"><img src={process.env.PUBLIC_URL + "/img/map_outline.svg"} alt={""}/></span>
                      <span className="menu-icon hidden"><img src={process.env.PUBLIC_URL + "/img/map.svg"} alt={""}/></span>
                      карта
                    </div>
                  </a>
                </li>
                <li className={isActive(isOpenSubscribe)}>
                  <a onClick={()=>dispatchFooter({type: 'OpenSubscribe'})}>
                    <div className="menu-link" >
                      <span className="menu-icon"><img src={process.env.PUBLIC_URL + "/img/share_outline.svg"} alt={""}/></span>
                      <span className="menu-icon hidden"><img src={process.env.PUBLIC_URL + "/img/share.svg"} alt={""}/></span>
                      поделиться
                    </div>
                  </a>
                </li>
                <li className="menu-burger">
                  <a onClick={OpenHeaderMenu}>
                    <div className="menu-link">
                      <div className="burger">
                        <div className="icon-group">
                          <span className="icon-bar"></span>
                          <span className="icon-bar"></span>
                          <span className="icon-bar"></span>
                        </div>
                      </div>
                      меню
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      {isOpenSubscribe && <SubscribeModal />}
    </Context.Provider>
  )
} 

export default FooterView;