// @flow
import 'tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.min.css';
import React, { useContext, useEffect, useReducer } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import Context from './../../../context';
import { GetValuesSensor, GetValuesSensorSelectTime, Territories } from './../../../common/Parser';
import SensorGraph from './SensorGraph';
import SensorSelectTimeHistory from './SensorSelectTimeHistory';
import ReactLoading from 'react-loading';
import { isTest } from '../../../route';
import SensorValueListTest, { SensorStaticValuesTest } from './../../../test/SensorValueListTest';
import type { SensorModel } from './../../../models/SensorModel'
import { ChangeSizeCanvas } from './SensorGraph';

function reducer(state, action: any) {
  window.removeEventListener('resize', ChangeSizeCanvas);
  switch (action.type) {
    case 'OpenGraphic':
      return {...state, currentSensorList: action.currentSensorList , isLoading: false, isOpenGraph: true, isOpenSelectTime: false};
    case 'OpenSelectTime':
      return {...state, isLoading: false, isOpenGraph: false, isOpenSelectTime: true, tabName: action.tabName}; 
    case 'LoadValue':
      const paramSelectTime = (action.paramSelectTime !== undefined) ? action.paramSelectTime : state.paramSelectTime; 
      const tabName =  (action.tabName !== undefined) ? action.tabName : state.tabName;
      return {...state, isLoading: true, isOpenGraph: false, isOpenSelectTime: false, tabName, paramSelectTime};
    default:
      throw new Error();
  }
}

const SensorHistoryModal = () => {
  const {stateSensor, setStateSensor} = useContext(Context);
  const nameTerritory = Territories.find(terr => terr.index === stateSensor.sensor.objectMap.terrId).name;
  const [historyState, dispatchHistory] = useReducer(reducer, initialState(stateSensor.sensor));
  const {isLoading, isOpenGraph, isOpenSelectTime,tabName, paramSelectTime} = historyState;

  useEffect(() => {
    if (!isLoading) return;
    if (tabName === selectTime) 
      getValuesSensorSelectTime();
    else
      getValueSensor(tabName);
  });

  const getValueSensor = (param: string) => {
    var str: string = "24"
    if (param === "week") {
      str = "7";
    } else if (param === "month") {
      str = "30";
    }
    operationId++;
    const checkId = operationId;
    if(isTest) {
      if(checkId === operationId){
        const response = SensorStaticValuesTest(param);
        dispatchHistory({type: 'OpenGraphic', currentSensorList: response});
      }
    } else {
      GetValuesSensor(stateSensor.sensor, str)
      .then(response => {
        if(checkId === operationId)
          dispatchHistory({type: 'OpenGraphic', currentSensorList: response});
        return;
      })
    }
  };

  const getValuesSensorSelectTime = () => {
    operationId++;
    const checkId = operationId;
    if(isTest) {
      const response = SensorValueListTest(paramSelectTime.startTime, paramSelectTime.endTime);
      if(checkId === operationId)
        dispatchHistory({type: 'OpenGraphic', currentSensorList: response});
    } else {
      GetValuesSensorSelectTime(stateSensor.sensor, paramSelectTime.time, paramSelectTime.startTime, paramSelectTime.endTime)
      .then(response => {
        if(checkId === operationId)
          dispatchHistory({type: 'OpenGraphic', currentSensorList: response});
        return;
      });
    }
  }

  const selectTab = (tabName: sring) => {
    operationId++;
    if (tabName === selectTime) {
      dispatchHistory({type: 'OpenSelectTime', tabName});
    } else {
      dispatchHistory({type: 'LoadValue', tabName});
    }
  }

  const GetTabActive = (param: string) => {
    am4core.disposeAllCharts();
    var className = "nav-link";
    if (param === tabName)
      className = "nav-link active";
    return className;
  }

  return(
    <Context.Provider value={{historyState, dispatchHistory}}>
      <div className="modal-window modal-absolute modal-ind modal-dark" id="windowsHistory">
        <div className="modal-inner">
          <div className="modal-pad">
            <div className="modal-top">
              <div className="modal-top-inner">
                <h2 className="modal-head">{stateSensor.sensor.objectMap.name}</h2>
                <h4>{nameTerritory}</h4>
                <button type="button" className="modal-close" onClick={()=>setStateSensor({...stateSensor, isOpenHistory: false})}>
                  <span className="diagonal-line"></span>
                  <span className="diagonal-line-reverse"></span>
                </button>
              </div>
            </div>
            <div className="modal-main">
              <div className="modal-tabs d-flex">
                <h4 className="col-title align-items-center">Архив показаний</h4>
                <ul className="nav flex-fill">
                  <li className="nav-item" onClick={()=>selectTab(dayValue)} >
                    <a className={GetTabActive(dayValue)} >24 часа</a>
                  </li>
                  <li className="nav-item" onClick={()=>selectTab(weekValue)} >
                    <a className={GetTabActive(weekValue)} >7 дней</a>
                  </li>
                  <li className="nav-item" onClick={()=>selectTab(monthValue)} >
                    <a className={GetTabActive(monthValue)} >30 дней</a>
                  </li>
                  <li className="nav-item" onClick={()=>selectTab(selectTime)} >
                    <a className={GetTabActive(selectTime)} >интервал дат</a>
                  </li>
                </ul>
              </div>
              <div className="tab-content">
                {isLoading && <ReactLoading className="App-loading" type='spin' color="#00e676" />}
                {isOpenGraph && <SensorGraph />}
                {isOpenSelectTime && <SensorSelectTimeHistory />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Context.Provider>
  );
}

export default SensorHistoryModal;

const dayValue = "day";
const weekValue = "week";
const monthValue = "month";
const selectTime = "select-time";
var operationId: namber = 0;


const initialState = (sensor: SensorModel) => {
  const newState = {
    isLoading: true,
    isOpenGraph: false,
    isOpenSelectTime: false,
    currentSensorList: [], 
    tabName: dayValue,
    icon: sensor.objectMap.icon,
    paramSelectTime: {
      time: 'h',
      startTime: null,
      endTime: null
    }
  };
  return newState;
}