import React, {useContext, useState, useEffect} from 'react';
import ReactLoading from 'react-loading';
import Portal from '../portal';
import Context from '../context';
import { OpenTextWindowModal } from './TextWindowModal';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import { OpenErrorModal } from './ErrorModal';

const FeedbackModal = () => {
  const [state, setState] = useState({
    isLoad: false,
    name: '',
    email: '',
    message: '',
  });
  const{stateHeader, setStateHeader} = useContext(Context);
  const {isLoad, name, email, message} = state;
  useEffect(() => {
    if(isLoad) return;
    var form = document.getElementById("FeedbackForm");
    form.Name.value = name;
    form.Email.value = email;
    form.Message.value = message
  })
  const onCancel = () => {
    setStateHeader({...stateHeader, isOpenFeedback: false});
  }

  const handleSubmit = event => {
    event.preventDefault();
    
    const form = document.getElementById("FeedbackForm");
    const name = form.Name.value;
    const email = form.Email.value;
    const message = form.Message.value;
    setState({...state, isLoad: true, name, email, message});
    const bodyFormData = new FormData();
    bodyFormData.set("username", form.Name.value);
    bodyFormData.set("email", form.Email.value);
    bodyFormData.set("message", form.Message.value);

    axios.post(process.env.PUBLIC_URL + "/feedbackmessage/index.php", bodyFormData)
      .then((res => {
        OpenTextWindowModal("Сообщение", "Сообщение успешно отправлено");
        onCancel();
      }))
      .catch(err=> {
        if(isMobile) {
          OpenErrorModal("Ошибка при отправке сообщения.")
        } else {
          OpenTextWindowModal("Ошибка", "Ошибка при отправке сообщения.");
        }
        setState({...state, isLoad: false, name, email, message});
      })
  }

  return(
    <Portal>
      <div className="modal-window modal-overlay modal-white" style={{zIndex:1000}}>
        <div className="modal-inner">
          <div className="modal-pad">
            <div className="modal-top">
              <div className="modal-top-inner">
                <button type="button" className="modal-close" onClick={onCancel}>
                  <span className="diagonal-line"></span>
                  <span className="diagonal-line-reverse"></span>
                </button>
              </div>
            </div>
            <div className="modal-main">
              <h2 className="modal-head">Обратная связь</h2>
              { isLoad ? 
                <ReactLoading className="modal-form" type='bubbles' color="#0067dc" />
                :
                <form className="modal-form" id="FeedbackForm" onSubmit={handleSubmit}>
                  <label className="form-label">
                    <input type="text" placeholder="Пожалуйста, представьтесь" className="form-control" name="Name" required/>
                  </label>
                  <label className="form-label">
                    <input type="email" placeholder="E-mail" className="form-control" name="Email" required/>
                  </label>
                  <label className="form-label">
                    <textarea placeholder="Сообщение" rows="5" className="form-control" name="Message" required>
                    </textarea>
                  </label>
                  <button type="submit" className="btn col-8 col-sm-6">
                    Отправить
                  </button>
                </form>
              }
            </div>
          </div>
        </div>
      </div>
    </Portal>
  )
}

export default FeedbackModal;