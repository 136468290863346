// @flow

import React, { useState } from 'react';
import Portal from '../portal';

export var OpenTextWindowModal : (title: string, textWindow: string) => void;

const TextWindowModal = () => {
  const [stateTextWindow, setStateTextWindow] = useState({isOpen:false, title:'', textWindow:''});

  OpenTextWindowModal = (title: string, textWindow: string) => setStateTextWindow({...stateTextWindow, isOpen:true, title, textWindow});
  const{title, textWindow} = stateTextWindow

  return(
    <Portal>
      { stateTextWindow.isOpen &&
        <div className="modal-window modal-overlay modal-white">
          <div className="modal-inner">
            <div className="modal-pad">
              <div className="modal-top">
                <div className="modal-top-inner">
                  <button type="button" className="modal-close" onClick={()=>setStateTextWindow({...stateTextWindow, isOpen:false})}>
                    <span className="diagonal-line"></span>
                    <span className="diagonal-line-reverse"></span>
                  </button>
                </div>
              </div>
              <div className="modal-main">
                <h2 className="modal-head">{title}</h2>
                <p className="margin-head" dangerouslySetInnerHTML={{__html: textWindow}}></p>
              </div>
            </div>
          </div>
        </div>
      }
    </Portal>
  )
}

export default TextWindowModal;