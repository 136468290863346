// @flow

import React, { } from 'react';
import { Sensors } from '../../../common/Parser';
import SensorView from './SensorView'

const FactoryView = ({factory}) => {
  const sensors = Sensors.filter(sensor => sensor.props.objectMap.terrId == factory.objectMap.terrId);
  const classFactory = (factory.objectMap.icon === "factory") ? "sensor-list-ter factory" : "sensor-list-ter npp";
  
  return (
    <div className={classFactory}>
      <div className="sensor-ter-title col-md-12 col-lg-4 col-xl-6">
        <div className="sensor-ter-title-bg">
          <h2>{factory.objectMap.name}</h2>
        </div>
      </div>
      {sensors.map(sensor => <SensorView sensor={sensor.props} key={sensor.props.objectMap.id}/>)}
    </div>
  )
}

export default FactoryView;