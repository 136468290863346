import React, {useEffect, useContext, useState} from 'react';
import $ from 'jquery'; 
import moment from 'moment';
import momentRU from 'moment/locale/ru';
import Context from '../../../context';
import TabSelectTime from './TabSelectTime';

window.jQuery = window.$ = $;
moment.locale('ru', momentRU);
window.moment = moment;
require('tempusdominus-bootstrap-4');

const SensorSelectTimeHistory = () => {
  const[stateTimeHistory, setStateTimeHistory] = useState({
    activeDetail: listSelectTimeId[0],
    detail: null
  });
  const{activeDetail} = stateTimeHistory;

  useEffect(()=>getData(stateTimeHistory, setStateTimeHistory));
  const{dispatchHistory} = useContext(Context);

  const getValuesSensorSelectTime = () => {
    var param: string;
    switch(activeDetail){
      case(listSelectTimeId[0]):
        param = 'h';
        break;
      case(listSelectTimeId[1]):
        param = 'd';
        break;
      case(listSelectTimeId[2]):
        param = 'm';
        break;
      default:
        param = 'y';
        break;
    }

    if(startTime === undefined || endTime === undefined) {
      alert('Вывести табличку: "Неправильно ввели тест"');
    } else {
      dispatchHistory({type: 'LoadValue', paramSelectTime: {time: param, startTime: startTime.unix(), endTime: endTime.unix()}});
    }
  }

  return (
    <Context.Provider value={{stateTimeHistory, setStateTimeHistory, listSelectTimeId}}>
      <div id="select-time-params">
        <div className="interval-date d-flex" >
          <h4 className="col-title col-auto align-items-center">Укажите интервал дат для вывода архива</h4>
          <div className="interval-date-label col col-sm-6">
            <div className="row no-gutters">
              <label className="col-auto label-col">с</label>
              <label className="col form-label form-label-white label-select-white">
                <input className="form-control form-control-white datetimepicker-input" id="datetimepicker" data-toggle="datetimepicker" data-target="#datetimepicker"/>
              </label>
              <div className="input-group-prepend">
                <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
              </div>
            </div>
            <div className="row no-gutters">
              <label className="col-auto label-col">по</label>
              <label className="col form-label form-label-white label-select-white">
                <input className="form-control form-control-white datetimepicker-input" id="datetimepicker2" data-toggle="datetimepicker" data-target="#datetimepicker2" />
              </label>
              <div className="input-group-prepend">
                <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
              </div>
            </div>
          </div>
        </div>
        <TabSelectTime />
        <button type="button" className="btn mt-4" onClick={getValuesSensorSelectTime} >
          показать
        </button>
      </div>
    </Context.Provider>
  )
}

export default SensorSelectTimeHistory;

const listSelectTimeId = ['hour-detail', 'day-detail', 'month-detail', 'year-detail'];
var startTime = moment().subtract(1, 'days');
var endTime = moment();

const getData = (state: any, setState: any) => {
  $(document).ready(function() {
    $(function () {
      $('#datetimepicker').unbind();
      $('#datetimepicker2').unbind();
      $('#datetimepicker').datetimepicker({
        defaultDate: startTime,
        maxDate: moment(),
        locale: 'ru',
        icons: {
          time: "fa fa-clock",
          date: "fa fa-calendar",
          up: "fa fa-arrow-up",
          down: "fa fa-arrow-down"
        }
      });
      $('#datetimepicker2').datetimepicker({
        defaultDate: endTime,
        minDate: moment().subtract(1, 'days'),
        maxDate: moment(),
        locale: 'ru',
        icons: {
          time: "fa fa-clock",
          date: "fa fa-calendar",
          up: "fa fa-arrow-up",
          down: "fa fa-arrow-down"
        }
      });
      $("#datetimepicker").on("change.datetimepicker", function (e) {
        $('#datetimepicker2').datetimepicker('minDate', e.date);
        startTime = e.date;
        getTime();
      });
      $("#datetimepicker2").on("change.datetimepicker", function (e) {
        $('#datetimepicker').datetimepicker('maxDate', e.date);
        endTime = e.date;
        getTime();
      });
    });
  });

  const getTime = () => {
    if(Number.isNaN(endTime - endTime)){
      endTime = moment();
    } else if (Number.isNaN(startTime - startTime)){
      startTime = endTime.subtract(1, 'days');
    }
    const daysLag = Math.floor((endTime - startTime) / (1000 * 3600 * 24));
    if (daysLag < 4) {
      setDisabledDetail(['hour-detail']);
    } else if (daysLag < 5) {
      setDisabledDetail(['hour-detail', 'day-detail']);
    } else if (daysLag < 90) {
      setDisabledDetail(['day-detail']);
    } else if (daysLag < 100) {
      setDisabledDetail(['day-detail', 'month-detail']);
    } else if (daysLag < 1080) {
      setDisabledDetail(['month-detail']);
    } else if (daysLag < 3000) {
      setDisabledDetail(['month-detail', 'year-detail']);
    } else {
      setDisabledDetail(['year-detail']);
    }
  }
  
  const setDisabledDetail = (listEnable: Array<string>) => {
    var isNewActive: boolean = (listEnable.find(id => id === state.activeDetail) === undefined);
    if (isNewActive) {
      const activeDetail = listEnable[0];
      const detail = (listEnable.length > 1) ? listEnable[1] : null;
      setState({...state, activeDetail, detail});
    } else {
      const detail = listEnable.find(id => id !== state.activeDetail);
      setState({...state, detail});
    }
  }
}

Array.prototype.diff = function(a) {
  return this.filter(function(i) {return a.indexOf(i) < 0;});
}