import React, {useContext} from 'react';
import Context from './../../context';
import { WindowTitels, GetWindowContent } from './../../common/Services';
import { OpenTextWindowModal } from './../../modals/TextWindowModal';

const HeaderMenu = (props) => {
  const{stateHeader, setStateHeader} = useContext(Context);

  const OpenWindow = (value: number) => {
    const title = WindowTitels[value];
    OpenTextWindowModal(title, GetWindowContent(title));
    setStateHeader({...stateHeader, isOpenMenu: false});
  };
  return(
    <>
      <div className="menu">
        <div className="menu-inner">
          <button type="button" className="modal-close" onClick={()=>setStateHeader({...stateHeader, isOpenMenu: false})}>
            <span className="diagonal-line"></span>
            <span className="diagonal-line-reverse"></span>
          </button>
          <div className="menu-nav-container">
              <ul className="menu-nav">
                <li><a onClick={()=>OpenWindow(0)}> {WindowTitels[0]} </a></li>
                <li><a onClick={()=>OpenWindow(1)}> {WindowTitels[1]} </a></li>
                <li><a onClick={()=>OpenWindow(2)}> {WindowTitels[2]} </a></li>
              </ul>
          </div>
          <div className="menu-bottom">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <a className="btn-link" onClick={()=>setStateHeader({...stateHeader, isOpenMenu: false, isOpenFeedback:true})}>Обратная связь</a>
                </div>
                <div className="col-12 copyright">
                  <p>© 2020. <a href="http://ibrae.ac.ru/" style={linkStyle} target="_blank">ИБРАЭ</a>. Все права защищены.</p>
                </div>
                <div className="col-12">
                  <a href="https://www.ritori.ru/" target={"_blank"} className="dev">Разработка сайта —
                    <img src={process.env.PUBLIC_URL + "/img/ritori.svg"} alt={''} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeaderMenu;

const linkStyle = {
  color: '#6f7780',
}