import React, {useContext} from 'react';
import Context from '../../../context';
import ButtonHistory from './../../../common/ButtonHistory';
import TooltipHistory from './../../../common/TooltipHistory';
import { isMobile } from 'react-device-detect';
import { OpenErrorModal } from './../../ErrorModal';

const TabSelectTime = () => {
  const{stateTimeHistory, setStateTimeHistory, listSelectTimeId} = useContext(Context);
  const{activeDetail, detail} = stateTimeHistory;

  const getClass = (name: string) => {
    var className = 'nav-link disabled';
    if(activeDetail === name)
      className = 'nav-link active';
    else if (detail === name)
      className = 'nav-link';
    return className;
  }

  const getButton = (value: number, text: string) => {
    let helpText = null;
    const activeId = listSelectTimeId.indexOf(activeDetail);
    const detailId = (detail == null) ? activeId : listSelectTimeId.indexOf(detail);
    if (Math.min(activeId, detailId) > value) {
      helpText = "Необходимо уменьшить диапазон дат";
    } else if (Math.max(activeId, detailId) < value) {
      helpText = "Необходимо увеличить диапазон дат";
    } 
    const currentButton = <a className={getClass(listSelectTimeId[value])} onClick={()=>setStateTimeHistory({...stateTimeHistory, activeDetail: listSelectTimeId[value], detail: activeDetail})}>{text}</a>
    var newButton = null;
    if(helpText == null) {
      newButton = <li className="nav-item">{currentButton}</li>;
    } else {
      if(isMobile)
        newButton = <li className="nav-item" onClick={()=>OpenErrorModal(helpText)}>{currentButton}</li>
      else
        newButton = <TooltipHistory message={helpText} customButton={<ButtonHistory currentButton={currentButton} />} />
    }
    return newButton;
  }

  return(
    <div className="d-flex detail">
      <h4 className="col-title col-auto align-items-center">Укажите детализации показаний</h4>
      <ul className="nav">
        {getButton(0, "по часам")}
        {getButton(1, "по дням")}
        {getButton(2, "по месяцам")}
        {getButton(3, "по годам")}
      </ul>
    </div>
    )
}

export default TabSelectTime;