import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import ReactLoading from 'react-loading';
import { Territories } from '../../common/Parser';
import { MainLink } from '../../route';
import { OpenTextWindowModal } from './../TextWindowModal';
import Context from '../../context';
import { isMobile } from 'react-device-detect';
import { OpenErrorModal } from './../ErrorModal';


const SubscribeForm = () => {
  const[state, setState] = useState({
    isLoad:false, 
    email: '',
    terrId: ''
  });
  const{dispatchFooter} = useContext(Context);
  const{isLoad, email, terrId} = state
  useEffect(() => {
    if(isLoad) return;
    const form = document.getElementById("SubscribeForm");
    Territories.map(territory => {
      let option = document.createElement("option");
      option.text = territory.name;
      option.value = territory.index;
      form.SelectTerritory.add(option);
    });
    form.SelectTerritory.value = terrId;
    form.Email.value = email;
  })

  const handleSubmit = event => {
    const form = document.getElementById("SubscribeForm");
    const email = form.Email.value;
    const terrId = form.SelectTerritory.value;
    setState({isLoad:true, email, terrId});
    event.preventDefault();
    axios.post(MainLink + "/scripts/maillist.php", {
      senderEmail: email,
      territory: terrId
    })
      .then(res => {
        OpenTextWindowModal("Подписаться на рассылку", "Вы успешно подписались на рассылку по данной территории.");
        dispatchFooter({type: 'CloseSubscribe'});
      })
      .catch(err => {
        if(isMobile) {
          OpenErrorModal("Ошибка при подписке на рассылку.")
        } else {
          OpenTextWindowModal("Ошибка", "Ошибка при подписке на рассылку.");
        }
        setState({isLoad:false, email, terrId});
      })
  }

  return(
    <>
      <h2 className="modal-head">подписаться на рассылку</h2>
      {isLoad ?
        <ReactLoading className="modal-form" type='bubbles' color="#0067dc"/>
        :
        <form className="modal-form" id="SubscribeForm" onSubmit={handleSubmit}>
          <label className="form-label">
            <input type="email" placeholder="E-mail" className="form-control" name="Email" required />
          </label>
          <label className="form-label form-label-select">
            <select className="form-control" name="SelectTerritory" required>
              <option value="">Выберите территорию</option>
            </select>
          </label>
          <input type="submit" value="Отправить"  className="btn col-8 col-sm-6"/>
        </form>
      }
      <div className="pat"></div>
    </>
  )
}

export default SubscribeForm;