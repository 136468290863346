import React, {useState} from 'react';
import Context from '../../context';
import HeaderMenu from './HeaderMenu';
import FeedbackModal from '../../modals/FeedbackModal';

export var OpenHeaderMenu: () => void;

const HeaderView = (props) => {
  const[stateHeader, setStateHeader] = useState({
    isOpenMenu:false,
    isOpenFeedback: false,
  })
  
  OpenHeaderMenu = () => setStateHeader({...stateHeader, isOpenMenu: true});
  const{isOpenMenu, isOpenFeedback} = stateHeader;

  return(
    <Context.Provider value={{stateHeader, setStateHeader}}>
      <header className="header">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="header-left">
                <a className="header-logo"><img src={process.env.PUBLIC_URL + "/img/logo.png"} alt={""}/></a>
                <div className="header-title">
                  <span>Институт проблем безопасного развития атомной энергетики РАН</span>
                  <h1>радиационная обстановка на предприятиях росатома</h1>
                </div>
              </div>
              <button type="button" className="burger" onClick={OpenHeaderMenu}>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </div>
          </div>
        </div>
      </header>
      {isOpenMenu && <HeaderMenu />}
      {isOpenFeedback && <FeedbackModal />}
    </Context.Provider>
  )
}

export default HeaderView;