// @flow

import { Sensors, Factories } from './../common/Parser'
import { OpenSensorModal } from '../modals/sensor/SensorModal.js';
import type { SensorModel } from './../models/SensorModel';
import type { ObjectYandexModel } from './../models/ObjectYandexModel'


export const OpenObjcet = (id: string) => {
  UpdateObject(Factories.find(factory => factory.props.objectMap.id === id));
}

export const OpenSensor = (id: string) => {
  const sensor: ObjectYandexModel<SensorModel> = Sensors.find(sensor => sensor.props.objectMap.id === id);
  if(sensor != null){
    OpenSensorModal(sensor.props);
  }
}

const UpdateObject = (data) => {
  // console.log(data);
}