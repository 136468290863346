// @flow
import React from 'react';
import { isMobile } from 'react-device-detect';
import { OpenTextWindowModal } from './../modals/TextWindowModal';
import { WindowTitels, GetWindowContent } from './Services';

const ButtonHelp = React.forwardRef((props, ref) => 
  <button {...props} ref={ref} className="btn-outline btn-round btn-white float-right" 
    style={{zIndex:1, cursor:"default"}} onClick={clickOnButton}>?</button>);

export default ButtonHelp;

const clickOnButton = () => {
  if(!isMobile) return;
  const title = WindowTitels[0];
  OpenTextWindowModal(title, GetWindowContent(title));
}