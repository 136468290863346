// @flow

import React, {useState, useEffect} from 'react';
import { Territories } from '../../common/Parser';
import { MainLink } from '../../route';
import { OpenSupportModal } from './../SupportModal';
import { OpenErrorModal } from './../ErrorModal';

const GetLinkForm = () => {
  const [territoryId, setTerritoryId] = useState(0);
  
  useEffect(() => {
    let form = document.getElementById("SettingsForm");
    if (form.SelectTerritory.options.length === 1){
      Territories.map(territory => {
        let option = document.createElement("option");
        option.text = territory.name;
        option.value = territory.index;
        form.SelectTerritory.add(option);
      });
    }
  });
  
  const copy = (size_x: string, size_y: string) => {
    const text =  '<a href="' + MainLink + '" title="Радиационная обстановка"><img src="' + MainLink + '/mediafiles/informers/t' + 
      territoryId +'_' + size_x + 'x' + size_y +'.jpg" width="' + size_x + '" height="'+ size_y + '" alt="Радиационная обстановка" border="0"/></a>';
    navigator.clipboard.writeText(text)
    .then(() => {
      OpenSupportModal('Текст успешно скопирован!');
      return;
    })
    .catch(err => {
      console.log('Something went wrong', err);
      OpenErrorModal("Ошибка при копировании текста!");
    });
  }

  return(
    <>
      <h2 className="modal-head">Добавить на свой сайт</h2>
      <p className="margin-head">Выберите необходимую территорию и скопируйте код для подходящего вам информера.</p>
      <div className="informer">
        <form id="SettingsForm">
          <label className="form-label form-label-select">
            <select className="form-control" name="SelectTerritory" onChange={event=>setTerritoryId(event.target.value)}>
              <option>Выберите территорию</option>
            </select>
          </label>
        </form>
        { (territoryId !== 0) &&
          <div className="informer-frame" style={{display: "block"}}>
            <div className="row informer-frame-block">
              <div className="informer-frame-img">
                <img src={MainLink + "/mediafiles/informers/t" + territoryId + "_200x100.jpg"} alt={""}/>
              </div>
              <div className="col align-self-center">
                <span className="informer-frame-text">200х100px</span>
                <br/>
                <button type="button" className="btn" onClick={()=>copy("200","100")}>
                  скопировать
                </button>
              </div>
            </div>
            <div className="row informer-frame-block">
              <div className="informer-frame-img text-right">
                <img src={MainLink + "/mediafiles/informers/t" + territoryId + "_150x150.jpg"} alt={""}/>
              </div>
              <div className="col align-self-center">
                <span className="informer-frame-text">150х150px</span>
                <br/>
                <button type="button" className="btn" onClick={()=>copy("150","150")}>
                  скопировать
                </button>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  )
}

export default GetLinkForm;