// @flow
import React, { useState, useEffect} from 'react';
import Portal from './../portal'

export let OpenSupportModal: (message: string) => void;
let timerID = 0;
const SupportModal = () => {
  const [state, setState] = useState(null)
  OpenSupportModal = (message: string) => setState(message);
  useEffect(() => {
    if(state == null) return;
    clearInterval(timerID);
    timerID = setInterval(()=>setState(null), 2000);
  });
  
  return(
    <>
      { state != null &&
        <Portal>
          <div className="modal-npp modal-small modal-dark" onClick={()=>setState(null)} style={{zIndex: 5000}}>
            <div className="modal-inner">
              <div className="modal-pad">
                <div className="modal-top">
                  <div className="alert" style={{color: 'white'}}>
                    {state}
                  </div>
                </div>
              </div>	
            </div>	
          </div>
        </Portal>
      }
    </>
  )
}

export default SupportModal;