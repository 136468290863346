import React, {useEffect} from 'react';
import YandexMap from './YandexMap';
import SensorListModal from '../../modals/sensor/list/SensorListModal';
import { InitZoomController, ClickOnRuler } from './../../common/ControllerZoom';

const ViewMap = (props) => {
  useEffect(InitZoomController);
  return(
    <section className="main">
    <SensorListModal />
      <div className="map">
        <div className="map-control">
          <div className="btn-absolute">
            <a role="button" data-toggle="collapse" href="#collapseScale" aria-expanded="false" aria-controls="collapseScale" className="collapse-link">
              <div className="radscale">
                <span className="radscale-line purple">> 2</span>
                <span className="radscale-line violet">0,6-2</span>
                <span className="radscale-line darkgreen">0,3-0,6</span>
                <span className="radscale-line green">0-0,3</span>
                <span className="units">мкЗв</span>
              </div>
            </a>
            <div className="collapse-block collapse" id="collapseScale">
              <a role="button" data-toggle="collapse" href="#collapseScale" aria-expanded="false" aria-controls="collapseScale" className="float-right">
                <i className="fa fa-times" aria-hidden="true"></i>
              </a>
              <div className="media collapse-line align-items-stretch">
                <span className="media-block purple ">> 2 мкЗв</span>
              </div>
              <div className="media collapse-line align-items-stretch">
                <span className="media-block violet">0,6-2 мкЗв</span>
                <div className="media-body">
                  Превышение естественного природного фона более чем в 2 раза
                </div>
              </div>
              <div className="media collapse-line align-items-stretch">
                <span className="media-block darkgreen align-items-stretch">0,3-0,6 мкЗв</span>
                <div className="media-body">
                  Превышение естественного природного фона не более чем в 2 раза
                </div>
              </div>
              <div className="media collapse-line align-items-stretch">
                <span className="media-block green align-items-stretch">0-0,3 мкЗв</span>
                <div className="media-body">
                  Уровень естественного природного фона
                </div>
              </div>
            </div>
          </div>
          
          <div className="map-zoom" id="divMapZoom">
            <div className="map-scale" style={{color: "white"}}>
              <button className="zoom-in btn-outline btn-round" id = "PlusBtn">
                <span className="line-hor"></span>
                <span className="line-vert"></span>
              </button>
              <div className="map-bar">
                <div className="map-object">
                  <span className="obj-home">дом</span>
                  <span className="obj-city">город</span>
                  <span className="obj-country">страна</span>
                </div>
                <div className="map-group">
                  <button className="map-line-runner" style={{top: '41px'}} id="ScrollZoom"></button>
                  
                  <div className="map-line" onMouseDown={event=>ClickOnRuler(event)}>
                    <span className="map-line-step"></span>
                    <span className="map-line-step"></span>
                    <span className="map-line-step"></span>
                    <span className="map-line-step"></span>
                    <span className="map-line-step"></span>
                    <span className="map-line-step"></span>
                    <span className="map-line-step"></span>
                    <span className="map-line-step"></span>
                    <span className="map-line-step"></span>
                    <span className="map-line-step"></span>
                    <span className="map-line-step"></span>
                    <span className="map-line-step"></span>
                    <span className="map-line-step"></span>
                    <span className="map-line-step"></span>
                    <span className="map-line-step"></span>
                  </div>
                </div>
              </div>
              <button className="zoom-out btn-outline btn-round" id = "MinusBtn">
                <span className="line-hor"></span>
              </button>
            </div>
            <button className="map-sputnik btn-round" id="TextureBtn" style={{background: "url(" + process.env.PUBLIC_URL + "/img/sputnik.png) no-repeat center"}}></button>
          </div>
        </div>
        <YandexMap />
      </div>
    </section>
  )
}

export default ViewMap;