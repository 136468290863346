// @flow

import React, { useState, useEffect } from 'react';
import { Territories, Factories } from '../../../common/Parser';
import FactoryView from './FactoryView';
import { CloseSensorModel } from './../SensorModal';

export var OpenSensorList: (isOpen: boolean) => void;

const SensorListModal = () => {
  const[sensorListState, setSensorListState] = useState({
    isOpen: false,
    terrId: 0
  });
  const {isOpen, terrId} = sensorListState;
  OpenSensorList = (isOpen: boolean) => setSensorListState({...sensorListState, isOpen, terrId:0});

  useEffect(() => {
    if(!isOpen) return;
    CloseSensorModel();
    let form = document.getElementById("TerritoryForm");
    if (form.SelectTerritory.options.length === 1){
      Territories.map(territory => {
        let option = document.createElement("option");
        option.text = territory.name;
        option.value = territory.index;
        form.SelectTerritory.add(option);
      });
    };
  });
  return(
    <>
      { isOpen && 
        <div className="main-container">
          <div className="top-heading">
            <div className="container">
              <div className="row">
                <div className="search-ter col-12 col-sm-12 col-md-12 col-lg-3 col-xl-4">
                  <form id="TerritoryForm">
                    <label className="form-label form-label-white label-select-white">
                      <select className="form-control form-control-white" name="SelectTerritory"  onChange={event=>setSensorListState({...sensorListState, terrId:event.target.value})}>
                        <option>со всех территорий</option>
                      </select>
                    </label>
                  </form>
                </div>
                <div className="table-title col-12 col-sm-12 col-md-12 col-lg-8">
                  <div className="row">
                    <div className="d-none d-sm-none d-md-block col-md-5">пост контроля</div>
                    <div className="col-5 col-sm-6 col-md-2">Время<sup>Мск</sup></div>
                    <div className="col-7 col-sm-6 col-md-5">Мощность дозы<sup>мкЗв/ч</sup></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-body">		
            <div className="container">		
              <div className="row">
                <div className="sensor-body col-12 col-sm-12 col-md-12 col-lg-9 col-xl-8 offset-lg-3 offset-xl-4">
                    <div className="sensor-list">
                      { terrId===0 ?
                        Factories.map(factory => <FactoryView factory={factory.props} key={factory.props.objectMap.id}/>)
                        :
                        <FactoryView factory={Factories.find(factory => factory.props.objectMap.terrId == terrId).props}/>
                      }
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default SensorListModal;