import React from 'react';
import './ymap.css';
import { Sensors, Factories } from '../../common/Parser.js'
import { OpenObjcet, OpenSensor } from '../../common/ControllerClick'
import { YMaps, Map, ObjectManager } from 'react-yandex-maps';
import { SetMap, SetSensorManager, CurrentZoom, ChangeScrollPosition } from '../../common/ControllerZoom';
import { isMobile } from 'react-device-detect';
import { SetOpenMobileFooter } from '../../modals/MobileFooterModal';

export var ZoomPosition: (coordinates: array<number>) => void;
var factoryManager;
var sensorManager;
var map;

const YandexMap = (props) => {
  ZoomPosition = (coordinates: array<number>) => {
    map.setCenter(coordinates);
    ChangeScrollPosition(14); 
  };

  const mapState = { zoom: CurrentZoom, center: [55.75, 37.57] }
  return (
    <div>
      <YMaps>
        <Map className="App-fullscreen"   //https://tech.yandex.ru/maps/jsbox/2.1/restrict_map_area
          state = {mapState}
          defaultOptions = {{suppressMapOpenBlock: true}}
          instanceRef = {inst => {
            if(inst == null) return;
            SetMap(inst)
            map = inst;
            inst.events.add('actiontick', (e) => {
              var tick = e.get('tick');
              if(CurrentZoom === tick.zoom) return; 
              ChangeScrollPosition(tick.zoom);
            })
          }}
        > 
          {/* Создаем ярлыки датчиков */}
          <ObjectManager
            options={{
              clusterize: false,
              gridSize: 1,
            }}
            objects={{
              openBalloonOnClick: false,
              preset: 'islands#greenDotIcon',
            }}
            clusters={{
              preset: 'islands#redClusterIcons',
            }}
            filter={object => object.props.objectMap.zoomShow < CurrentZoom}
            modules={[
              'objectManager.addon.objectsHint',
            ]}
            instanceRef = {inst => initSensors(inst)}
          />

          {/* Создаем ярлыки заводов */}
          <ObjectManager
            options={{
              clusterize: false,
              gridSize: 32,
            }}
            objects={{
              openBalloonOnClick: false,
              preset: 'islands#greenDotIcon',
            }}
            clusters={{
              preset: 'islands#redClusterIcons',
            }}
            modules={[
              'objectManager.addon.objectsHint',
            ]}
            instanceRef = {inst => initFactorty(inst)}
          />
        </Map>
      </YMaps>
    </div>
  )
}

export default YandexMap;

const initSensors = (inst: any) => {
  if(sensorManager != null){
    inst.removeAll();
    var sensorsMap = {
      type: 'FeatureCollection',
      features: Sensors,
    }
    inst.add(sensorsMap);

    inst.objects.events.add('click', function (e) {
      let objId = e.get('objectId');
      OpenSensor(objId);
    })
  }
  SetSensorManager(inst);
  sensorManager = inst;
}

const initFactorty = (inst: any) => {
  if(factoryManager != null){
    inst.removeAll();
    var factoriesMap = {
      type: 'FeatureCollection',
      features: Factories,
    }
    inst.add(factoriesMap);

    inst.objects.events.add('click', function (e) {
      let objId = e.get('objectId');
      clickOnFactory(objId);
      OpenObjcet(objId);
    })
  }
  factoryManager = inst;
}


const clickOnFactory = (objId: string) => {
  let obj = Factories.find(factory => factory.props.objectMap.id === objId);
  
  if (isMobile) {
    SetOpenMobileFooter(obj.props.objectMap.name, ()=> {
      map.setCenter(obj.geometry.coordinates);
      ChangeScrollPosition(12); 
    });
  } else {
  //Если зум меньше 8 то приближаем приближаем к месту ярлыка
    if(CurrentZoom < 8) {
      map.setCenter(obj.geometry.coordinates);
      ChangeScrollPosition(12); 
      return;
    }
  }
}