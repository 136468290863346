// @flow

import React, {useContext, useEffect} from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4lang_ru_RU from "@amcharts/amcharts4/lang/ru_RU";
import Context from '../../../context';
import type {SensorValueModel} from '../../../models/SensorValueModel'
import { GetIcon } from './../../../common/Services';
import TooltipHelp from '../../../common/TooltipHelp';
import { HelpContent } from '../../../common/Parser';
import ButtonHelp from './../../../common/ButtonHelp';
import { TresholdList } from './ServicesSelectTime';
import type { TresholdModel } from "../../../models/TresholdModel";
import { isMobile } from 'react-device-detect';

export var ChangeSizeCanvas = () => {};

const SensorGraph = () => {
  const{historyState} = useContext(Context);
  const{currentSensorList, icon} = historyState
  
  const getTreshold = (pos: number) => {
    am4core.disposeAllCharts();
    currentTreshold = TresholdList[pos];
    setDataDiagram(currentSensorList, currentTreshold);
  }

  useEffect(()=>{
    if(currentSensorList.length === 0) return;
    setDataDiagram(currentSensorList, currentTreshold);
    const select = document.getElementById('selectCountry');
    select.selectedIndex = TresholdList.indexOf(currentTreshold);
    settingsCanvas(icon, currentSensorList);
    ChangeSizeCanvas = () => settingsCanvas(icon, currentSensorList);
    window.removeEventListener('resize', ChangeSizeCanvas);
    window.addEventListener('resize', ChangeSizeCanvas);
  });
  
  return(
    <div className="graph-block" id="graphBlock" style={{position: "relative"}} >
      { currentSensorList.length === 0 ?
        <>
          <div className="no-data" style={{marginTop: "100px"}}>
            <img src={process.env.PUBLIC_URL+"/img/stop_icon_w.svg"} alt={""}></img>
            <h4>За этот период нет показаний</h4>
          </div>
        </>
        :
        <>
          <canvas className="App-canvas-graph" id="canvasGraph"></canvas>
          <div id="chartdiv"></div>
          <div className="compare-block d-flex">
            <h4 className="compare-block-title align-items-center">Сравнивать с природным фоном</h4>
            <div className="compare-block-form align-self-end">
              <label className="form-label form-label-white label-select-white">
                <select className="form-control form-control-white" id="selectCountry" onChange={value=>getTreshold(value.target.selectedIndex)}>
                  <option>в России</option>
                  <option>во Франции</option>
                  <option>в Италии</option>
                  <option>в Бразилии</option>
                </select>
              </label>
            </div>
            <TooltipHelp message={HelpContent.find(cont => cont.name === "graph").description} customButton={<ButtonHelp />} />
          </div>
        </>
      }
    </div>
  )
}

export default SensorGraph;

const settingsCanvas = (icon: string, sensorValues: Array<SensorValueModel>) => {
  const div = document.getElementById('graphBlock');
  const c_canvas = document.getElementById('canvasGraph');
  var context = c_canvas.getContext("2d");
  c_canvas.width = div.clientWidth;
  c_canvas.height = div.clientHeight;

  let maxWidth = c_canvas.width;
  let minWidth = 0.5;
  let maxHeight = c_canvas.height - 116.8;
  let minHeight = 14;

  if(isMobile || Math.max(document.documentElement.clientWidth,window.innerWidth) <= 575){
    maxWidth = c_canvas.width;
    minWidth = 0.5;
    maxHeight = c_canvas.height - 84;
    minHeight = 14.5;
  }  

  context.beginPath();
  
  for (var x = minWidth; x <= maxWidth; x += 5) {
    context.moveTo(x, minHeight);
    context.lineTo(x, maxHeight);
  }
  for (var y = minHeight; y <= maxHeight; y += 5) {
    context.moveTo(0, y);
    context.lineTo(maxWidth, y);
  }
  context.fillStyle = getColor();
  context.lineWidth = 0.1;
  context.fillRect(0,minHeight,maxWidth,maxHeight-minHeight);
  context.strokeStyle = "#ddd";
  context.stroke();

  function getColor() {
    var color: string = icon;
    if(icon === 'gray' || icon === 'repair' )
      color = GetIcon(icon, getMaxValue());
    var newColor: string;
    switch(color) {
      case 'darkgreen':
        newColor = '#006666';
        break;
      case 'violet':
        newColor = '#333399';
        break;
      case 'purple':
        newColor = '#9933ff';
        break;
      default:
        newColor = '#339933';
        break;
    }
    return newColor;
  }

  function getMaxValue() {
    var maxValue = 0;
    sensorValues.forEach(data => {
      if(data.value > maxValue)
        maxValue = data.value;
    })
    return maxValue;
  }
}

const setDataDiagram = (sensorValues: Array<SensorValueModel>, tresholds: Array<TresholdModel>) => {
  am4core.ready(function() {
    const whiteColor = am4core.color("#ffffff");

    var chart = am4core.create("chartdiv", am4charts.XYChart);
    
    // chart.dispose();
    chart.data = generateChartData();
    chart.language.locale = am4lang_ru_RU;

    // chart.dx = 0;
    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 60;
    dateAxis.renderer.labels.template.fill = whiteColor;
    dateAxis.renderer.grid.template.stroke = whiteColor;
    dateAxis.renderer.grid.template.strokeWidth = 1.2;
    dateAxis.renderer.grid.template.strokeOpacity = 0.3;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.min = 0;
    valueAxis.max = Math.max(
      tresholds.reduce((prev,cur) => cur.value>prev.value?cur:prev,{value:-Infinity}).value + 0.04, 
      sensorValues.reduce((prev,cur) => cur.value>prev.value?cur:prev,{value:-Infinity}).value
    );
    valueAxis.renderer.labels.template.opacity = 0;
    valueAxis.renderer.grid.template.strokeWidth = 0;
    
    background();
    tresholds.forEach(treshold => generateRangeData(treshold));
    
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "value";
    series.dataFields.dateX = "date";
    series.strokeWidth = 2;
    series.stroke = whiteColor;

    var circleBullet = series.bullets.push(new am4charts.CircleBullet());
    circleBullet.circle.stroke = whiteColor;

    if(isMobile) {
      circleBullet.circle.radius = 5;
    } else {
      circleBullet.circle.radius = 3;
    }
    
    circleBullet.fill = whiteColor;
    //console.log(circleBullet.dataItem);

    circleBullet.adapter.add("tooltipHTML", function(tooltipHTML, target){
      if (!target.dataItem) {
        return tooltipHTML;
      }
      var value = target.dataItem.values.valueY.value;
      //return  `<div class="select-point"><div class="select-point--value"><br/>Нет данных</div><img style="width:24px; height:24px;"src="./img/hex.svg" alt={""}/></div>`;
      if (value<0) {
        series.tooltip.dx = -48;
        series.tooltip.dy = -138.5;
        return `<div class="select-point"><div class="select-point--value"><br/>Нет данных</div><img style="width:24px; height:24px;"src="./img/hex.svg" alt={""}/></div>`;
      } else {
        series.tooltip.dx = -59.8;
        series.tooltip.dy = -138.5;
        return `<div class="select-point"><div class="select-point--value">Мощность дозы<br/> {valueY} мкЗв</div><img style="width:24px; height:24px;"src="./img/hex.svg" alt={""}/></div>`;
      }
    })
    circleBullet.tooltipHTML = getTooltip(series.dataFields.valueY);
    
    series.tooltip.autoTextColor = false;
    series.tooltip.label.fill = whiteColor;
    series.tooltip.contentAlign = 'right';
    series.tooltip.contentValign = 'bottom';

    series.tooltip.background.opacity = 0;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;
    chart.cursor.snapToSeries = series;
    chart.cursor.lineY.strokeOpacity = 0;
    
    function generateChartData() {
      var chartData = [];     
      for (var i = 0; i < sensorValues.length; i++) {
        var sensor: SensorValueModel = sensorValues[i];
        var newDate = new Date();
        newDate.setTime(sensor.time*1000);
        chartData.push({
          date: newDate,
          value: sensor.value
        });
      }
      return chartData;
    }

    function generateRangeData(treshold: TresholdModel) {
      var range = valueAxis.axisRanges.create();
      range.value = treshold.value;
      if(isMobile){
        range.grid.scale = 1.5;
        range.grid.strokeWidth = 0.56;
      } else {
        range.grid.scale = 1.3;
        range.grid.strokeWidth = 0.65;
      }
      range.grid.strokeOpacity = 0.4;
      range.grid.stroke = whiteColor;
      range.grid.dx = -100;
      range.label.inside = true;
      range.label.fill = range.grid.stroke;
      var bullet = new am4charts.Bullet();
      range.bullet = bullet;
      
      bullet.tooltip = new am4core.Tooltip();
      bullet.tooltipHTML = '<div class="select-tooltip">' + treshold.title + '<div>'
      var image = bullet.createChild(am4core.Image);
      image.width = 28;
      image.height = 20;
      image.verticalCenter = "bottom";
      image.dy = -5;
      image.href = treshold.img;

      bullet.fill = whiteColor;
      bullet.tooltip.getFillFromObject = false;
      bullet.tooltip.contentAlign = 'right';
      bullet.tooltip.contentValign = 'none';
      bullet.tooltip.dx = -12;
      bullet.tooltip.dy = -20;
      bullet.tooltip.background.opacity = 0;
      bullet.dx = -50;
    }

    function background() {
      var range = valueAxis.axisRanges.create();
      range.value = 0;
      range.endValue = 10000;
    }

    function getTooltip(value: number) {
      if (value<0) {
        return `<div class="select-point"><div class="select-point--value"><br/>Нет данных</div><img style="width:24px; height:24px;"src="./img/hex.svg" alt={""}/></div>`
      } else {
        return `<div class="select-point"><div class="select-point--value">Мощность дозы<br/> {valueY} мкЗв</div><img style="width:24px; height:24px;"src="./img/hex.svg" alt={""}/></div>`
      }
    }
  });
}

var currentTreshold: Array<TresholdModel> = TresholdList[0];