// @flow

import React from 'react';
import type { SensorModel } from './../../../models/SensorModel'
import { ZoomPosition } from './../../../views/body/YandexMap';
import { OpenMap } from '../../../views/footer/FooterView';
import { GetColor } from '../../../common/Services';
import { OpenSensorGraphModel } from './../SensorModal'

const SensorView = ({sensor}) => {
  let isOpenPosition = false;
  const colorBG = GetColor(sensor.objectMap.icon);
  const checkValue = () => {
    let sensorProp = null;
    if(sensor.value != null)
      sensorProp = WorkingSensor(sensor);
    else if(colorBG === 'stop')
      sensorProp = StopSensor(sensor);
    else
      sensorProp = RepairSensor(sensor);
    return sensorProp;
  } 
  
  const openGraph = () => {
    if(isOpenPosition) return;
    OpenMap();
    OpenSensorGraphModel(sensor);
  }
  return(
    <div className={"row sensor-block sensor-" + colorBG} onClick={()=>openGraph()}>
      <div className="sensor-name order-1 col-10 order-sm-1 col-sm-9 col-md-5 pad-both align-self-center">
        {sensor.objectMap.name}
      </div>
      {checkValue()}
      <div className="sensor-map-point order-2 col-2 order-sm-2 col-sm-3 col-md-1 order-md-4">
        <button className="btn-outline btn-round" onClick={() => {ZoomPosition([sensor.objectMap.lat, sensor.objectMap.lng]); OpenMap(); isOpenPosition=true}}></button>
      </div>
    </div>
  )
}

const WorkingSensor = (sensor: SensorModel) => {
  var newDate = new Date();
  newDate.toLocaleDateString();
  newDate.setTime(sensor.time*1000);
  return (
    <>
      <div className="sensor-time order-3 col-5 order-sm-3 col-sm-6 col-md-2 order-md-2 pad-both align-self-center">
        <div className="sensor-time-inner">{GetDate(newDate)}<br/> {newDate.getHours()}:{GetMinutes(newDate.getMinutes())}</div>
      </div>
      <div className="sensor-value order-4 col-7 order-sm-4 col-sm-6 col-md-4 order-md-3  pad-both align-self-center">{sensor.value}</div>
    </>
  )
}

const RepairSensor = (sensor: SensorModel) => {
  return(
    <div className="sensor-info order-3 col-12 col-sm-12 order-sm-3 col-md-6 order-md-2 pad-both"><span className="sensor-info-text">датчик на ремонте</span></div>
  )
}

const StopSensor = (sensor: SensorModel) => {
  return(
    <div className="sensor-info order-3 col-12 col-sm-12 order-sm-3 col-md-6 order-md-2 pad-both"><span className="sensor-info-text">нет данных</span></div>
  );
}

const GetMinutes = (minutes: number) => {
  if (minutes < 10) {
    return "0" + minutes;
  } else {
    return minutes;
  }
}

const GetDate = (date: date) => {
  return date.getDate() + " " + months[date.getMonth()];
}

const months = ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек']
export default SensorView;