import React, {useContext} from 'react';
import Context from '../../context';
import GetLinkForm from './GetLinkForm';
import SubscribeForm from './SubscribeForm';
import ShareForm from './ShareForm';

const SubscribeModal = () => {
  const{dispatchFooter} = useContext(Context);

  return(
    <div className="modal-window modal-overlay modal-white">
      <div className="modal-inner">
        <div className="modal-pad">
          <div className="modal-top">
            <div className="modal-top-inner">
              <button type="button" className="modal-close" onClick={()=>dispatchFooter({type: 'CloseSubscribe'})}>
                <span className="diagonal-line"></span>
                <span className="diagonal-line-reverse"></span>
              </button>
            </div>
          </div>
          <div className="modal-main">
            <SubscribeForm />
            <ShareForm />
            <div className="pat"></div>
            <GetLinkForm />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubscribeModal;