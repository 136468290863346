// @flow

import type { TresholdModel } from "../../../models/TresholdModel"

export const TresholdList: Array<Array<TresholdModel>> = [
  //Россия
  [{
      title: "Средний уровень по РФ (0.095 мкЗв/ч)",
      value: 0.095,
      img: "./img/russia_flag.png"
    },
    {
      title: "Средний уровень<br/> в республике Бурятия (0.20 мкЗв/ч)",
      value: 0.2,
      img: "./img/buryatia.png"
    },
    {
      title: "Набережная невы (гранит)<br/> в Санкт-Петербурге (0.45 мкЗв/ч)",
      value: 0.45,
      img: "./img/stpeter.png"
  }],
  //Франция
  [{
      title: "Средний уровень<br/> во Франции (0.098 мкЗв/ч)",
      value: 0.0980,
      img: "./img/france_flag.png"
    },
    {
      title: "Центральная область Франции,<br/> район гранитов, сланцев, <br/>песчаника (0.43 мкЗв/ч)",
      value: 0.43,
      img: "./img/france_flag.png"
  }],
  //Италия
  [{
      title: "Средний уровень<br/> в Италии (0.104 мкЗв/ч)",
      value: 0.104,
      img: "./img/italy_flag.png"
    },
    {
      title: "Провинция Кампанья (0.23 мкЗв/ч)",
      value: 0.23,
      img: "./img/italy_flag.png"
    },
    {
      title: "Город Орвието (0.59 мкЗв/ч)",
      value: 0.59,
      img: "./img/italy_flag.png"
  }],
  //Бразилия
  [{
      title: "Средний уровень<br/> в Бразилии (0.13 мкЗ/ч)",
      value: 0.13,
      img: "./img/brazil_flag.png"
    },
    {
      title: "Гаурапари (монацитовые пески, <br/>прибрежные районы)<br/> Мощность дозы естественного <br/> фона до 90 мкЗ/ч",
      value: 90,
      img: "./img/brazil_flag.png"
  }]
]