import React, {useEffect, useState} from 'react';
import Axios from 'axios';
import { Parser } from './common/Parser';
import MapView from './views/body/MapView';
import HeaderView from './views/header/HeaderView';
import FooterView from './views/footer/FooterView';
import { DataSourseLink, isTest } from './route';
import SensorModal from './modals/sensor/SensorModal';
import MobileFooterModal from './modals/MobileFooterModal';
import TextWindowModal from './modals/TextWindowModal';
import ErrorModal from './modals/ErrorModal';
import SupportModal from './modals/SupportModal';

const App = (props) => {
  const [startApp, setStartApp] = useState(true);
  useEffect(() => {
    if(!startApp) return;
    const link = isTest ? process.env.PUBLIC_URL + '/test/last_indications.xml' : DataSourseLink + '/last_indications.php';
    Axios.get(link)
    .then(response => {
      Parser(response.data);
      setStartApp(false);
      return;
    })
  });
  return(
    <>
      { !startApp &&
        <>
          <HeaderView />
          <MapView />
          <FooterView />
          <SensorModal />
          <MobileFooterModal />
          <TextWindowModal />
          <ErrorModal />
          <SupportModal />
        </>
      }
    </>
  )
}

export default App