// @flow
import React, { useState, useEffect} from 'react';
import Portal from './../portal'

export let OpenErrorModal: (error: string) => void;
let timerID = 0;
const ErrorModal = () => {
  const [state, setState] = useState(null)
  OpenErrorModal = (error: string) => setState(error);
  useEffect(() => {
    if(state == null) return;
    clearInterval(timerID);
    timerID = setInterval(()=>setState(null), 2000);
  });
  
  return(
    <>
      { state != null &&
        <Portal>
          <div className="modal-npp modal-small modal-dark" onClick={()=>setState(null)} style={{zIndex: 5000}}>
            <div className="modal-inner">
              <div className="modal-pad">
                <div className="modal-top">
                  <div className="alert">
                    <i className="fas fa-exclamation-triangle"></i> {state}
                  </div>
                </div>
              </div>	
            </div>	
          </div>
        </Portal>
      }
    </>
  )
}

export default ErrorModal;