var scroll = null;
var sensorManager = null;
var map;

export const InitZoomController = () => {
  if(scroll != null) return;
  scroll = document.getElementById('ScrollZoom');
  ClickScroll();
  ZoomButtons();
  ChangeMapTexture();
  ChangeScrollPosition(CurrentZoom);
}

export var CurrentZoom = 5;

export const SetMap = (ymap) => {
  map = ymap
}

export const SetSensorManager = (manager) => {
  sensorManager = manager;
}

export const ChangeScrollPosition = (zoom) => {
  CurrentZoom = zoom;
  let convertZoom = 19 - zoom;
  scroll.style.top = (convertZoom + convertZoom / 19 * 0.6) * 5 + "px";
  if(sensorManager == null) return;
  map.setZoom(CurrentZoom);
  sensorManager.setFilter(object => object.props.objectMap.zoomShow < CurrentZoom);
}

export const ClickOnRuler = (event) => {
  let newPos = event.clientY - document.documentElement.clientHeight / 2 + 37;
  if (newPos < 0) {
    newPos = 0;
  } else if(newPos > 98) {
    newPos = 98
  }
  const scaleSize = 98/15;
  const scalePos = Math.round(newPos / scaleSize);
  newPos = scalePos * scaleSize;
  const newZoom = 19 - newPos * 19 / 98;
  ChangeScrollPosition(newZoom);
}

const checkSizeZoom = (zoom) => {
  if(zoom < 0) zoom = 0;
  else if (zoom > 19) zoom = 19;
  
  sensorManager.setFilter(object => object.props.objectMap.zoomShow < zoom);
  map.setZoom(zoom);
  CurrentZoom = zoom;
  let convertZoom = 19 - zoom;
  scroll.style.top = (convertZoom + convertZoom / 19 * 0.6) * 5 + "px";
}


const ClickScroll = () => {
  scroll.addEventListener('touchstart', ontouchstart);
  function ontouchstart(event){
    scroll.addEventListener('touchmove', touchMove);
    document.addEventListener('touchend', touchEnd);
    
    function touchMove(event) {
      let newPos = event.touches[0].clientY - document.documentElement.clientHeight / 2 + 37;
      onMove(newPos);
    }

    function touchEnd() {
      document.removeEventListener('touchend', touchEnd);
      document.removeEventListener('touchmove', touchMove);
    }
  }

  scroll.onmousedown = function(event) {
    event.preventDefault(); // предотвратить запуск выделения (действие браузера)
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    function onMouseMove(event) {
      let newPos = event.clientY - document.documentElement.clientHeight / 2 + 37;
      onMove(newPos);
    }

    function onMouseUp() {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    }
  };

  function onMove(newPos: number){
    if (newPos < 0) {
      newPos = 0;
    } else if(newPos > 98) {
      newPos = 98;
    }
    scroll.style.top = newPos + 'px';
    const newZoom = Math.round(19 - newPos * 19 / 98);
    
    if(CurrentZoom === newZoom) return;
    CurrentZoom = newZoom;
    map.setZoom(CurrentZoom);
    sensorManager.setFilter(object => object.props.objectMap.zoomShow < CurrentZoom);
  }

  scroll.ondragstart = function() {
    return false;
  };
}

const ZoomButtons = () => {
  var plus = document.getElementById('PlusBtn');
  plus.onclick = () => { 
    checkSizeZoom(CurrentZoom + 1);
  }
  var minus = document.getElementById('MinusBtn');
  minus.onclick = () => { 
    checkSizeZoom(CurrentZoom - 1);
  }
}

const ChangeMapTexture = () => {
  let isSputnik = false ;
  const textureBtn = document.getElementById('TextureBtn');
  const div = document.getElementById('divMapZoom');
  textureBtn.onclick = () => { 
    if(isSputnik){
      isSputnik = false;
      textureBtn.style.background = "url(./img/sputnik.png) no-repeat center";
      const div = document.getElementById('divMapZoom');
      div.classList.remove('color-white')
      map.setType('yandex#map');
    } else {
      isSputnik = true;
      textureBtn.style.background = "url(./img/scheme.png) no-repeat center";
      div.classList.add('color-white')
      map.setType('yandex#satellite'); 
    }
  }
}