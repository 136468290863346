// @flow

import XMLParser from 'react-xml-parser';
import Axios from 'axios';
import { DataSourseLink, isTest } from '../route';
import { GetIcon } from './../common/Services';
import type { TerritoryModel } from '../models/TerritoryModel';
import type { SensorModel } from '../models/SensorModel.js';
import type { FactoryModel } from '../models/FactoryModels.js';
import type { ObjectYandexModel } from '../models/ObjectYandexModel';
import type { SensorValueModel } from '../models/SensorValueModel';
import type { FactoryDescriptionModel } from '../models/FactoryDescriptionModel';
import type { HelpContenModel } from '../models/HelpContenModel';


const parser = new DOMParser();
export let Sensors: Array<ObjectYandexModel<SensorModel>> = [];
export let Factories: Array<ObjectYandexModel<FactoryModel>> = [];
export let Territories: Array<TerritoryModel | empty> = [];
export let HelpContent: Array<HelpContenModel | empty> = [];

export const Parser = (xml: any) => {
  const data = ParserXML(xml);
  const parseParam = isTest ? "text/html" : "application/xml";
  const helpData = parser.parseFromString(xml, parseParam).getElementsByTagName("helpContents")[0]

  //Прасим список территриий
  let territories = data.getElementsByTagName("territory_list")[0].children;
  territories.map(territory => Territories.push(SetTerritory(territory.attributes)));

  Territories.sort((a, b) => {
    let nameA = a.name.toLowerCase();
    let nameB = b.name.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0
  });

  //Прасим список заводов (объектов)
  let objectList: Array<ObjectYandexModel<FactoryModel>> = [];
  const objects = data.getElementsByTagName("object_list")[0].children;
  objects.map(object => {
    const id = object.attributes.id;
    const description = SetDescriptionObject(data.getElementsByTagName(id)[0], getDiscription(id));
    let factory = CreateFactoryModel(object.attributes, description);
    objectList.push(CreateObjectYandex(factory, 'object'));
  });
  Territories.forEach(ter => {
    Factories.push(objectList.find(obj => obj.props.objectMap.terrId == ter.index))
  });

  //Прасим список сенсоров
  let sensorList: Array<ObjectYandexModel<SensorModel>> = [];
  let sensors = data.getElementsByTagName("sensor_list")[0].children;
  sensors.map(sensor => sensorList.push(CreateObjectYandex(CreateSensorModel(sensor.attributes), 'sensor')));
  Sensors = sensorList;

  //Прасим список текст для тултипов
  HelpContent = [{ name: "values", description: getDiscription("values") },
  { name: "graph", description: getDiscription("graph") }];

  function getDiscription(id: string) {
    let description = helpData.getElementsByTagName(id)[0].getElementsByTagName("text").item(0).textContent;
    return description;
  }
}

export const GetValuesSensor = async (sensor: SensorModel, param: string) => {
  const id = sensor.objectMap.id.split('_')[1];
  const path = DataSourseLink + '/get_indications_by_id.php?id=' + id + '&terr_id=' + sensor.objectMap.terrId + '&order=' + param;
  return parseListSensorValue(path);
}

export const GetValuesSensorSelectTime = async (sensor: SensorModel, param: string, startDate: number, endDate: number) => {
  const id = sensor.objectMap.id.split('_')[1];
  const path = DataSourseLink + '/get_indications_by_id.php?id=' + id + '&terr_id=' + sensor.objectMap.terrId + '&from=' + startDate + '&to=' + endDate + '&detail=' + param;
  return parseListSensorValue(path);
}

const ParserXML = (xmlText) => {
  let xml = new XMLParser().parseFromString(xmlText);
  return xml;
}

const parseListSensorValue = async (path: string) => {
  const response = await Axios.get(path);
  let listValue: Array<SensorValueModel> = [];
  if (ParserXML(response.data).attributes.status !== 'nodata') {
    let data: Array<any> = ParserXML(response.data).getElementsByTagName("archive")[0].children;
    data.map(sensorValue => listValue.push({ time: sensorValue.attributes.time, value: parseFloat(sensorValue.attributes.value / 100).toFixed(2) }));
  }
  return listValue;
}

const CreateObjectYandex = (object: any, name: string): ObjectYandexModel<any> => {
  let factoryYandex: ObjectYandexModel<any> = {
    props: object,
    name: name,
    type: 'Feature',
    id: object.objectMap.id,
    geometry: {
      type: 'Point',
      coordinates: [object.objectMap.lat, object.objectMap.lng]
    },
    properties: {
      hintContent: object.objectMap.name,
    },
    options: GetOptions(object.objectMap.icon)
  }
  return factoryYandex;
}

const CreateFactoryModel = (object: any, description: FactoryDescriptionModel): FactoryModel => {
  const newFactory: FactoryModel = {
    objectMap: {
      id: object.id,
      terrId: object.id.slice(1),
      lat: object.lat,
      lng: object.lng,
      zoomShow: object.zoomShow,
      icon: object.icon,
      name: object.name,
      description: description
    },
    description: description
  };
  return newFactory;
}

const CreateSensorModel = (sensor: any) => {
  const value: ?number = (sensor.value === undefined) ? null : parseFloat(sensor.value / 100).toFixed(2);
  const newSensor: SensorModel = {
    objectMap: {
      id: sensor.id,
      terrId: sensor.terrId,
      lat: sensor.lat,
      lng: sensor.lng,
      zoomShow: sensor.zoomShow,
      icon: GetIcon(sensor.icon, value),
      name: sensor.name,
    },
    value: value,
    time: sensor.time
  }
  return newSensor;
}

const GetOptions = (icon: string) => {
  const GetImageUrl = (): string => {
    var url = process.env.PUBLIC_URL + '/img/';
    switch (icon) {
      case 'factory':
        url += 'factory.svg';
        break;
      case 'station':
        url += 'npp.svg';
        break;
      default:
        url += icon + '_sensor.svg';
        break;
    };
    return url;
  }

  const GetSize = () => {
    var size = {};
    if (icon === 'station' || icon === 'factory') {
      size = {
        iconImageSize: [37, 43],
        iconImageOffset: [-18.5, -21.5]
      }
    } else {
      size = {
        iconImageSize: [24, 24],
        iconImageOffset: [-12, -12]
      }
    }
    return size;
  }

  const propImage = GetSize();
  const Options = {
    iconLayout: 'default#image',
    iconImageHref: GetImageUrl(),
    iconImageSize: propImage.iconImageSize,
    iconImageOffset: propImage.iconImageOffset
  }
  return Options;
}

const SetDescriptionObject = (propDescription: any, description: string): FactoryDescriptionModel => {
  let parsDescription: FactoryDescriptionModel = {
    img: propDescription.attributes,
    title: propDescription.children[0].value,
    description: description
  };
  return parsDescription;
}

const SetTerritory = (territory: any): TerritoryModel => {
  let parsTerritory: TerritoryModel = {
    index: territory.index,
    name: territory.name
  }
  return parsTerritory;
}