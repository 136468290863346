// @flow

import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    marginTop: '-5px',
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(11),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const TooltipHistory = ({message, customButton}) => {
  return(
    <HtmlTooltip
      title={
        <React.Fragment>
          {message}
        </React.Fragment>
      }
      >
      {customButton}
    </HtmlTooltip>
  )
}

export default TooltipHistory;