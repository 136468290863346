// @flow

import React, { useState } from 'react';
import Portal from './../portal';

export var SetOpenMobileFooter: (title: string, callBack: () => void) => void;

const MobileFooterModal = () => {
  const [stateMobileFooter, setStateMobileFotter] = useState({
    isOpen: false,
    zooming: ()=>{},
    title: "Factory"
  });
  SetOpenMobileFooter = (title: string, callBack: () => void) => setStateMobileFotter({
    isOpen: true,
    zooming: callBack,
    title: title
  });
  const {isOpen, title, zooming} = stateMobileFooter;
  return(
    <Portal>
      {isOpen &&
        <div className="modal-npp modal-small modal-dark">
          <div className="modal-inner">
            <div className="modal-pad">
              <div className="modal-top">
                <div className="modal-top-inner">
                  <h2 className="modal-head">{title}</h2>
                  <button type="button" className="modal-close" onClick={()=>setStateMobileFotter({...stateMobileFooter, isOpen: false})}>
                    <span className="diagonal-line"></span>
                    <span className="diagonal-line-reverse"></span>
                  </button>
                </div>
              </div>
              <div className="modal-main">
                <button type="button" className="btn" onClick={()=>{zooming(); setStateMobileFotter({...stateMobileFooter, isOpen: false})}}>
                  приблизить
                </button>
              </div>
            </div>	
          </div>	
        </div>
      }
    </Portal>
  );
}

export default MobileFooterModal;